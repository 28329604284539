import request from '@/utils/request'

export function clients() {
  return request({
    url: '/client/listClient/1',
    method: 'get'
  })
}

export function getPwd(path) {
  return request({
    url: `/getRedirectUrl?redirect=${path}`,
    method: 'get',
  })
}
