<template>
    <div class="pageBox">
        <div class="boxMain">
            <div class="BMtitle">【系统目录】</div>
            <div class="trBox" v-if="sysList.length>0">
                <div class="tdBox" v-for="(item, index) in sysList" :key="index">
                    <div class="tdMain" v-if="user.zoneTree.type == 3"  @click="toSys(item.redirectUri)">{{item.clientName}}</div>
                    <div class="tdMain" v-else style="cursor: not-allowed;opacity: 0.4;" >{{item.clientName}}</div> 
                </div>
            </div>
            <div v-else class="empty">
                暂无子系统
            </div>
        </div>
    </div>
</template>
<script>
import { clients, getPwd } from '@/api/home.js'
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            sysList:[],
        }
    },
    computed:{
      ...mapGetters([
        'user',
      ]),
    },
    mounted(){
        this.GETclients()
    },
    methods:{
        GETclients(){
            clients().then(r=>{
                this.sysList = r.data.lists
            })
        },
        toSys(path){
            getPwd(path).then(r=>{
                window.open(r.data)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}
.boxMain{
    width: 1240px;
    border: 1px dashed #854F33;
    box-sizing: border-box;
    .BMtitle{
        color: #854933;
        line-height: 68px;
        font-size: 24px;
        border-bottom: 1px dashed #854F33;
        box-sizing: border-box;
    }
    .trBox{
        width: 100%;
        padding: 50px 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .tdBox{
            width: 400px;
            height: 120px;
            box-sizing: border-box;
            border: 1px dashed #854F33;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            .tdMain{
                width: 280px;
                line-height: 100px;
                background-image: url('../../../assets/img/bgSys.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                cursor: pointer;
            }
        }
    }
    .empty{
        width: 100%;
        line-height: 100px;
        text-align: center;
        color: rgb(143, 143, 143);
    }
}
</style>